'use client'
import { ark, PolymorphicProps, type Assign } from '@ark-ui/react'
import { Tabs } from '@ark-ui/react/tabs'
import { type TabsVariantProps, tabs } from 'styled-system/recipes'
import type { ComponentProps, HTMLStyledProps } from 'styled-system/types'
import { createStyleContext } from './utils/create-style-context'

const { withProvider, withContext } = createStyleContext(tabs)

export type RootProviderProps = ComponentProps<typeof RootProvider>
export const RootProvider = withProvider<
  HTMLDivElement,
  Assign<Assign<HTMLStyledProps<'div'>, Tabs.RootProviderBaseProps>, TabsVariantProps>
>(Tabs.RootProvider, 'root')

export type RootProps = ComponentProps<typeof Root>
export const Root = withProvider<
  HTMLDivElement,
  Assign<Assign<HTMLStyledProps<'div'>, Tabs.RootBaseProps>, TabsVariantProps>
>(Tabs.Root, 'root')

export const Content = withContext<
  HTMLDivElement,
  Assign<HTMLStyledProps<'div'>, Tabs.ContentBaseProps>
>(Tabs.Content, 'content')

export const Indicator = withContext<
  HTMLDivElement,
  Assign<HTMLStyledProps<'div'>, Tabs.IndicatorBaseProps>
>(Tabs.Indicator, 'indicator')

export const List = withContext<HTMLDivElement, Assign<HTMLStyledProps<'div'>, Tabs.ListBaseProps>>(
  Tabs.List,
  'list',
)

export const Trigger = withContext<
  HTMLButtonElement,
  Assign<HTMLStyledProps<'button'>, Tabs.TriggerBaseProps>
>(Tabs.Trigger, 'trigger')

export { TabsContext as Context } from '@ark-ui/react/tabs'

/* 下記拡張 */
export const TriggerIconWrapper = withContext<HTMLDivElement, HTMLStyledProps<'div'>>(ark.div, 'triggerIconWrapper')
export const TriggerIcon = withContext<HTMLDivElement, Assign<HTMLStyledProps<'div'>, PolymorphicProps>>(ark.div, 'triggerIcon')
export const TriggerIconText = withContext<HTMLSpanElement, HTMLStyledProps<'span'>>(ark.span, 'triggerIconText')
export const TriggerText = withContext<HTMLParagraphElement, HTMLStyledProps<'p'>>(ark.p, 'triggerText')
export const TriggerContent = withContext<HTMLDivElement, HTMLStyledProps<'div'>>(ark.div, 'triggerContent' )
/* 上記拡張 */
